import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

function WaterIcon() {
  const groupRef = useRef();

  useFrame((state, delta) => {
    groupRef.current.rotation.y += delta * 0.5;
  });

  return (
    <group ref={groupRef}>
      <mesh>
        <sphereGeometry args={[0.5, 32, 32]} />
        <meshStandardMaterial color={'#3498db'} />
      </mesh>
      <mesh position={[0, 0.25, 0]}>
        <coneGeometry args={[0.3, 0.5, 32]} />
        <meshStandardMaterial color={'#2980b9'} />
      </mesh>
    </group>
  );
}

export default WaterIcon;