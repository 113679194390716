import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

function TankIcon() {
  const groupRef = useRef();

  useFrame((state, delta) => {
    groupRef.current.rotation.y += delta * 0.5;
  });

  return (
    <group ref={groupRef}>
      <mesh position={[0, -0.2, 0]}>
        <cylinderGeometry args={[0.5, 0.5, 0.8, 32]} />
        <meshStandardMaterial color={'#95a5a6'} />
      </mesh>
      <mesh position={[0, 0.4, 0]}>
        <sphereGeometry args={[0.3, 32, 32]} />
        <meshStandardMaterial color={'#7f8c8d'} />
      </mesh>
    </group>
  );
}

export default TankIcon;