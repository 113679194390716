import React, { useState, useEffect } from 'react';
import { useSpring, animated} from 'react-spring';
import './AnimatedText.css';

const getRandomPosition = () => {
  const positions = [
    { x: '-100%', y: '-100%' },
    { x: '100%', y: '-100%' },
    { x: '-100%', y: '100%' },
    { x: '100%', y: '100%' },
  ];
  return positions[Math.floor(Math.random() * positions.length)];
};

const AnimatedLetter = ({ letter, index }) => {
  const { x, y } = getRandomPosition();

  const props = useSpring({
    to: { opacity: 1, transform: 'translate(0%, 0%)' },
    from: { opacity: 0, transform: `translate(${x}, ${y})` },
    delay: index * 100,
    config: { tension: 200, friction: 15 },
  });

  return (
    <animated.span style={props} className="neon-letter">
      {letter}
      <span className="trail"></span>
    </animated.span>
  );
};

const AnimatedText = ({ text }) => {
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    setLetters(text.split(''));
  }, [text]);

  return (
    <h1 className="animated-text">
      {letters.map((letter, index) => (
        <AnimatedLetter key={index} letter={letter} index={index} />
      ))}
    </h1>
  );
};

export default AnimatedText;