import React, { useState, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { useSpring, animated } from 'react-spring';

// 3D icon components (you'll need to create these)
import HouseIcon from './3DIcons/HouseIcon';
import RobotIcon from './3DIcons/RobotIcon';
import BikeIcon from './3DIcons/BikeIcon';
import HeadIcon from './3DIcons/HeadIcon';
import AnimalIcon from './3DIcons/AnimalIcon';
import WaterIcon from './3DIcons/WaterIcon';
import TankIcon from './3DIcons/TankIcon';
import LightIcon from './3DIcons/LightIcon';

const ProjectCard = ({ project, onClick }) => {
  const [hovered, setHovered] = useState(false);

  const springProps = useSpring({
    scale: hovered ? 1.1 : 1,
    config: { mass: 1, tension: 280, friction: 60 }
  });

  const Icon = getIconComponent(project.id);

  return (
    <animated.div
      style={springProps}
      className="project-card"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => onClick(project)}
    >
      <h3>{project.title}</h3>
      <div style={{ width: '100%', height: '150px' }}>
        <Canvas>
          <Suspense fallback={null}>
            <ambientLight intensity={0.5} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
            <Icon />
            <OrbitControls enableZoom={false} enablePan={false} />
          </Suspense>
        </Canvas>
      </div>
    </animated.div>
  );
};

const Projects = ({ projects, onProjectClick }) => {
  return (
    <div className="content">
      <h2>My Projects</h2>
      <div className="projects-grid">
        {projects.map((project) => (
          <ProjectCard 
            key={project.id} 
            project={project} 
            onClick={onProjectClick}
          />
        ))}
      </div>
    </div>
  );
};

function getIconComponent(id) {
  switch(id) {
    case 1: return HouseIcon;
    case 2: return RobotIcon;
    case 3: return BikeIcon;
    case 4: return HeadIcon;
    case 5: return AnimalIcon;
    case 6: return WaterIcon;
    case 7: return TankIcon;
    case 8: return LightIcon;
    default: return HouseIcon;
  }
}

export default Projects;