import React from 'react';
import { useSpring, animated } from 'react-spring';
import AnimatedText from './AnimatedText';

const About = () => {
  const contentProps = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    delay: 2000, // Delay this to start after the text animation
  });

  return (
    <div className="content">
      <AnimatedText text="Elisa Kikota" />
      <animated.div style={contentProps} className="about-content">
        <p>
          I'm a 4th year telecom student at the University of Dar es Salaam, 
          passionate about IoT and Robotics. I specialize in creating innovative 
          solutions using microcontrollers like Arduinos, ESPs, and Raspberry Pis.
        </p>
      </animated.div>
    </div>
  );
};

export default About;