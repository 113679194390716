import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

function AnimalIcon() {
  const groupRef = useRef();

  useFrame((state, delta) => {
    groupRef.current.rotation.y += delta * 0.5;
  });

  return (
    <group ref={groupRef}>
      <mesh position={[0, 0.2, 0]}>
        <sphereGeometry args={[0.3, 32, 32]} />
        <meshStandardMaterial color={'#e67e22'} />
      </mesh>
      <mesh position={[0.2, -0.2, 0]}>
        <boxGeometry args={[0.2, 0.4, 0.2]} />
        <meshStandardMaterial color={'#e67e22'} />
      </mesh>
      <mesh position={[-0.2, -0.2, 0]}>
        <boxGeometry args={[0.2, 0.4, 0.2]} />
        <meshStandardMaterial color={'#e67e22'} />
      </mesh>
    </group>
  );
}

export default AnimalIcon;