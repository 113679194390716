import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

function LightIcon() {
  const groupRef = useRef();

  useFrame((state, delta) => {
    groupRef.current.rotation.y += delta * 0.5;
  });

  return (
    <group ref={groupRef}>
      <mesh>
        <sphereGeometry args={[0.3, 32, 32]} />
        <meshStandardMaterial color={'#f1c40f'} emissive={'#f1c40f'} emissiveIntensity={0.5} />
      </mesh>
      <mesh position={[0, -0.4, 0]} rotation={[Math.PI, 0, 0]}>
        <coneGeometry args={[0.2, 0.4, 32]} />
        <meshStandardMaterial color={'#2c3e50'} />
      </mesh>
    </group>
  );
}

export default LightIcon;