import React, { useState, useEffect, useCallback } from 'react';
import { useTransition, animated } from 'react-spring';
import About from './About';
import Projects from './Projects';
import './styles.css';

const ProjectModal = ({ project, onClose }) => {
  const animation = useTransition(project, {
    from: { opacity: 0, transform: 'translateY(-50px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-50px)' },
  });

  return animation((style, item) =>
    item && (
      <div className="modal-overlay" onClick={onClose}>
        <animated.div style={style} className="modal-content" onClick={e => e.stopPropagation()}>
          <h2>{item.title}</h2>
          <p>{item.description}</p>
          <button onClick={onClose}>Close</button>
        </animated.div>
      </div>
    )
  );
};

const App = () => {
  const [currentSection, setCurrentSection] = useState('about');
  const [selectedProject, setSelectedProject] = useState(null);

  const projects = [
    { id: 1, title: "Automated House", description: "A smart home system with automated controls for various household functions." },
    { id: 2, title: "Robot Car", description: "An autonomous vehicle with a versatile robotic arm for various tasks." },
    { id: 3, title: "Hover Bike", description: "A drone-like personal transportation device, resembling a delivery drone." },
    { id: 4, title: "Judisi", description: "An AI-powered robot head with vision capabilities and Swahili language interaction." },
    { id: 5, title: "Animal Tracking", description: "A wildlife monitoring solution using IoT technology." },
    { id: 6, title: "Smart Water Meter", description: "An IoT-based system for efficient water consumption monitoring." },
    { id: 7, title: "Tank Management", description: "A system for optimizing water storage and energy usage with generator integration." },
    { id: 8, title: "Home Automation", description: "An automated solution for home lighting and air circulation control." },
  ];

  const handleScroll = useCallback((event) => {
    const delta = event.deltaY;
    if (delta > 0 && currentSection === 'about') {
      setCurrentSection('projects');
    } else if (delta < 0 && currentSection === 'projects') {
      setCurrentSection('about');
    }
  }, [currentSection]);

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    return () => window.removeEventListener('wheel', handleScroll);
  }, [handleScroll]);

  const transitions = useTransition(currentSection, {
    from: { opacity: 0, transform: 'translate3d(0,100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-50%,0)' },
  });

  return (
    <div className="app">
      <div className="stars"></div>
      <div className="twinkling" style={{ zIndex: 0 }}></div>
      
      <nav className="nav">
        <button onClick={() => setCurrentSection('about')}>About</button>
        <button onClick={() => setCurrentSection('projects')}>Projects</button>
      </nav>

      {transitions((style, item) => (
        <animated.div style={style} className="section-container">
          {item === 'about' ? (
            <About />
          ) : (
            <Projects projects={projects} onProjectClick={setSelectedProject} />
          )}
        </animated.div>
      ))}

      <ProjectModal project={selectedProject} onClose={() => setSelectedProject(null)} />
    </div>
  );
};

export default App;