import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

function BikeIcon() {
  const groupRef = useRef();

  useFrame((state, delta) => {
    groupRef.current.rotation.y += delta * 0.5;
  });

  return (
    <group ref={groupRef}>
      <mesh position={[-0.5, 0, 0]}>
        <torusGeometry args={[0.3, 0.1, 16, 100]} />
        <meshStandardMaterial color={'#f39c12'} />
      </mesh>
      <mesh position={[0.5, 0, 0]}>
        <torusGeometry args={[0.3, 0.1, 16, 100]} />
        <meshStandardMaterial color={'#f39c12'} />
      </mesh>
      <mesh position={[0, 0.5, 0]} rotation={[0, 0, Math.PI / 4]}>
        <boxGeometry args={[1, 0.1, 0.1]} />
        <meshStandardMaterial color={'#2ecc71'} />
      </mesh>
    </group>
  );
}

export default BikeIcon;