import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

function HeadIcon() {
  const groupRef = useRef();

  useFrame((state, delta) => {
    groupRef.current.rotation.y += delta * 0.5;
  });

  return (
    <group ref={groupRef}>
      <mesh>
        <sphereGeometry args={[0.5, 32, 32]} />
        <meshStandardMaterial color={'#9b59b6'} />
      </mesh>
      <mesh position={[-0.2, 0.2, 0.4]}>
        <sphereGeometry args={[0.1, 32, 32]} />
        <meshStandardMaterial color={'#ecf0f1'} />
      </mesh>
      <mesh position={[0.2, 0.2, 0.4]}>
        <sphereGeometry args={[0.1, 32, 32]} />
        <meshStandardMaterial color={'#ecf0f1'} />
      </mesh>
    </group>
  );
}

export default HeadIcon;