import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

function HouseIcon() {
  const meshRef = useRef();

  useFrame((state, delta) => {
    meshRef.current.rotation.x += delta * 0.2;
    meshRef.current.rotation.y += delta * 0.3;
  });

  return (
    <mesh ref={meshRef}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={'#4a90e2'} />
    </mesh>
  );
}

export default HouseIcon;